// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-calendar {
	border: 0 !important;
}

.react-calendar__navigation {
	height: 2rem;
}

.react-calendar__navigation__arrow,
.react-calendar__navigation__label {
	background-color: inherit !important;
	font-size: 1.3rem;
}

.react-calendar__navigation__arrow:active {
	font-size: 1rem;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
	display: none;
}

.react-calendar__tile {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.react-calendar__tile:hover {
	background-color: #e6e6e6 !important;
}

.react-calendar__tile--active {
	color: black !important;
	background-color: inherit !important;
}

.react-calendar__tile:disabled {
	color: initial;
	background-color: initial;
}

.react-calendar__month-view__days__day.react-calendar__tile {
	height: 4rem;
	border-radius: 25%;
}

.react-calendar__year-view__months__month.react-calendar__tile {
	height: 5rem;
	border-radius: 20%;
	line-height: 0.5;
}

.react-calendar__tile--now {
	font-weight: bold;
	background-color: inherit !important;
	border: 1px solid gray !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/Calendar.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;AACrB;;AAEA;CACC,YAAY;AACb;;AAEA;;CAEC,oCAAoC;CACpC,iBAAiB;AAClB;;AAEA;CACC,eAAe;AAChB;;AAEA;;CAEC,aAAa;AACd;;AAEA;CACC,kBAAkB;CAClB,aAAa;CACb,sBAAsB;CACtB,8BAA8B;CAC9B,mBAAmB;AACpB;;AAEA;CACC,oCAAoC;AACrC;;AAEA;CACC,uBAAuB;CACvB,oCAAoC;AACrC;;AAEA;CACC,cAAc;CACd,yBAAyB;AAC1B;;AAEA;CACC,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,YAAY;CACZ,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;CACjB,oCAAoC;CACpC,iCAAiC;AAClC","sourcesContent":[".react-calendar {\n\tborder: 0 !important;\n}\n\n.react-calendar__navigation {\n\theight: 2rem;\n}\n\n.react-calendar__navigation__arrow,\n.react-calendar__navigation__label {\n\tbackground-color: inherit !important;\n\tfont-size: 1.3rem;\n}\n\n.react-calendar__navigation__arrow:active {\n\tfont-size: 1rem;\n}\n\n.react-calendar__navigation__prev2-button,\n.react-calendar__navigation__next2-button {\n\tdisplay: none;\n}\n\n.react-calendar__tile {\n\ttext-align: center;\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-between;\n\talign-items: center;\n}\n\n.react-calendar__tile:hover {\n\tbackground-color: #e6e6e6 !important;\n}\n\n.react-calendar__tile--active {\n\tcolor: black !important;\n\tbackground-color: inherit !important;\n}\n\n.react-calendar__tile:disabled {\n\tcolor: initial;\n\tbackground-color: initial;\n}\n\n.react-calendar__month-view__days__day.react-calendar__tile {\n\theight: 4rem;\n\tborder-radius: 25%;\n}\n\n.react-calendar__year-view__months__month.react-calendar__tile {\n\theight: 5rem;\n\tborder-radius: 20%;\n\tline-height: 0.5;\n}\n\n.react-calendar__tile--now {\n\tfont-weight: bold;\n\tbackground-color: inherit !important;\n\tborder: 1px solid gray !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
