import React from "react";

interface RecordIconProps {
	recordType: string;
	isHalf?: boolean;
}

const RecordIcon: React.FC<RecordIconProps> = ({ recordType, isHalf }) => {
	return recordType === "soju" && !isHalf ? (
		<img src="images/soju.png" alt="soju" />
	) : recordType === "soju" && isHalf ? (
		<img src="images/soju_half.png" alt="soju_half" />
	) : recordType === "beer" ? (
		<span>🍺</span>
	) : null;
};

export default RecordIcon;
