import React, { useState, useEffect } from "react";
import api from "../api";

interface LoginResponse {
	access_token: string;
	refresh_token: string;
}

const LoginPage: React.FC = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await api.get("/users/me");

				if (response.status === 200) window.location.href = "/";
			} catch (error: any) {
				if (error.response.status === 401) {
					return;
				}
				console.error(error);
			}
		};

		fetchUserData();
	}, []);

	const handleLogin = async () => {
		setError("");
		try {
			const response = await api.post<LoginResponse>("/users/login", {
				username,
				password,
			});
			const { access_token, refresh_token } = response.data;

			localStorage.setItem("access_token", access_token);
			localStorage.setItem("refresh_token", refresh_token);

			window.location.href = "/";
		} catch (err: any) {
			if (err.response && err.response.status === 401) {
				alert("아이디 또는 비밀번호가 일치하지 않습니다.");
			} else {
				console.error(err);
			}
		}
	};

	return (
		<div
			className="container is-flex is-justify-content-center is-align-items-center"
			style={{ minHeight: "100vh" }}
		>
			<div
				className="box"
				style={{ width: "100%", maxWidth: "400px", padding: "20px" }}
			>
				<h1 className="title has-text-centered">Login</h1>
				<div className="field">
					<label className="label">ID</label>
					<div className="control">
						<input
							className="input"
							type="text"
							placeholder="Enter your ID"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>
					</div>
				</div>
				<div className="field">
					<label className="label">Password</label>
					<div className="control">
						<input
							className="input"
							type="password"
							placeholder="Enter your password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
				</div>
				{error && <p className="help is-danger">{error}</p>}
				<div className="field">
					<div className="control">
						<button
							className="button is-primary is-fullwidth"
							onClick={handleLogin}
						>
							Login
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
