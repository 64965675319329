import React, { useEffect } from "react";
import "./css/App.css";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	Outlet,
} from "react-router-dom";
import CalendarPage from "./pages/CalendarPage";
import LoginPage from "./pages/LoginPage";
import ErrorPage from "./pages/ErrorPage";
import api from "./api";

const Layout: React.FC = () => {
	const offLineHandler = () => {
		alert("인터넷 연결이 끊겼습니다.");
	};

	window.addEventListener("offline", offLineHandler);

	useEffect(() => {
		return () => {
			window.removeEventListener("offline", offLineHandler);
		};
	});

	const handleLogout = () => {
		try {
			api.post("/users/logout", {
				access_token: localStorage.getItem("access_token"),
				refresh_token: localStorage.getItem("refresh_token"),
			});
		} catch (error: any) {
			alert("로그아웃에 실패했습니다.");
			console.error(error);
		}

		localStorage.removeItem("acccess_token");
		localStorage.removeItem("refresh_token");
		window.location.href = "/login";
	};

	return (
		<div className="App is-flex is-flex-direction-column">
			<h2 className="has-text-centered">알록</h2>
			<Outlet />
			<nav className="navbar is-fixed-bottom is-light">
				<div className="navbar-menu is-active">
					<div className="navbar-start is-flex is-justify-content-space-around">
						<Link to="/" className="button is-primary">
							달력
						</Link>
						<button
							onClick={handleLogout}
							className="button is-primary"
						>
							로그아웃
						</button>
					</div>
				</div>
			</nav>
		</div>
	);
};

const App: React.FC = () => {
	return (
		<Router>
			<Routes>
				<Route element={<Layout />}>
					<Route path="/" element={<CalendarPage />} />
				</Route>

				<Route path="/login" element={<LoginPage />} />
				<Route
					path="/error"
					element={<ErrorPage message="오류가 발생했습니다." />}
				/>
			</Routes>
		</Router>
	);
};

export default App;
