// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/bulma@1.0.2/css/bulma.min.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	overflow: hidden;
}

.App {
	height: 100vh;
}

.box {
	max-width: 400px;
}

.wide {
	padding-left: 0;
	padding-right: 0;
}

.grecaptcha-badge {
	visibility: hidden;
}

.ptr--text {
	display: none !important; /* Pull-to-Refresh 텍스트 숨기기 */
}
`, "",{"version":3,"sources":["webpack://./src/css/App.css"],"names":[],"mappings":"AAEA;;CAEC,SAAS;CACT,UAAU;CACV,YAAY;CACZ,gBAAgB;AACjB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,wBAAwB,EAAE,4BAA4B;AACvD","sourcesContent":["@import \"https://cdn.jsdelivr.net/npm/bulma@1.0.2/css/bulma.min.css\";\n\nhtml,\nbody {\n\tmargin: 0;\n\tpadding: 0;\n\theight: 100%;\n\toverflow: hidden;\n}\n\n.App {\n\theight: 100vh;\n}\n\n.box {\n\tmax-width: 400px;\n}\n\n.wide {\n\tpadding-left: 0;\n\tpadding-right: 0;\n}\n\n.grecaptcha-badge {\n\tvisibility: hidden;\n}\n\n.ptr--text {\n\tdisplay: none !important; /* Pull-to-Refresh 텍스트 숨기기 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
