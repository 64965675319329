// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar {
	width: 100%;
	height: 100%;
	overflow: auto;
}

.react-calendar {
	width: 100% !important;
	border: 0 !important;
}

.react-calendar__viewContainer {
	display: flex;
}

.react-calendar__navigation__label {
	font-size: 1rem;
}

.react-calendar__navigation__arrow {
	display: none;
}

.react-calendar__tile {
	height: 4.5rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.react-calendar__tile--active {
	color: black !important;
	background-color: inherit !important;
}

.react-calendar__month-view__days__day.react-calendar__tile {
	border-radius: 25%;
}

.react-calendar__year-view__months__month.react-calendar__tile {
	border-radius: 20%;
	line-height: 0.5;
}

.react-calendar__tile--now {
	font-weight: bold;
	background-color: inherit !important;
	border: 1px solid gray !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/Calendar.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,cAAc;AACf;;AAEA;CACC,sBAAsB;CACtB,oBAAoB;AACrB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,cAAc;CACd,kBAAkB;CAClB,aAAa;CACb,sBAAsB;CACtB,8BAA8B;CAC9B,mBAAmB;AACpB;;AAEA;CACC,uBAAuB;CACvB,oCAAoC;AACrC;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;CACjB,oCAAoC;CACpC,iCAAiC;AAClC","sourcesContent":[".calendar {\n\twidth: 100%;\n\theight: 100%;\n\toverflow: auto;\n}\n\n.react-calendar {\n\twidth: 100% !important;\n\tborder: 0 !important;\n}\n\n.react-calendar__viewContainer {\n\tdisplay: flex;\n}\n\n.react-calendar__navigation__label {\n\tfont-size: 1rem;\n}\n\n.react-calendar__navigation__arrow {\n\tdisplay: none;\n}\n\n.react-calendar__tile {\n\theight: 4.5rem;\n\ttext-align: center;\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-between;\n\talign-items: center;\n}\n\n.react-calendar__tile--active {\n\tcolor: black !important;\n\tbackground-color: inherit !important;\n}\n\n.react-calendar__month-view__days__day.react-calendar__tile {\n\tborder-radius: 25%;\n}\n\n.react-calendar__year-view__months__month.react-calendar__tile {\n\tborder-radius: 20%;\n\tline-height: 0.5;\n}\n\n.react-calendar__tile--now {\n\tfont-weight: bold;\n\tbackground-color: inherit !important;\n\tborder: 1px solid gray !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
